import React,{useEffect} from "react";
import styles from "./OurServices.module.css";
import AOS from "aos";
const OurServices = () => {

  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);
  const Partner = (props) => {
    const { partners } = props;
    return (
      <div className={styles["partner-card"]}>
        <img src={partners.image} alt="p"></img>
        <h2 className={styles["em-title"]}>{partners.title}</h2>
        <br />
        <p>{partners.detail}</p>
      </div>
    );
  };

  const partList = [
    {
      id: 1,
      image: "./images/web-development.png",
      title: "Web Development",
      detail:
      "Crafting user-friendly, aesthetically pleasing, and functionally robust websites that cater to the unique needs of our clients.",
    },
    {
      id: 2,
      image: "./images/web-app.png",
      title: "Web Applications",
      detail:
        "Developing web applications that are not only intuitive but also scalable, ensuring that your business grows seamlessly with technology.",
    },
    {
      id: 3,
      image: "./images/mobile-app-logo.png",
      title: "Mobile Application Development",
      detail:
        "In a mobile-first world, we specialize in creating engaging and efficient mobile applications for both Android and iOS platforms.",
    },
    {
      id: 4,
      image: "./images/iot-app.png",
      title: "IoT Devices and Software",
      detail:
        "We're at the forefront of the IoT revolution, developing devices and software that connect the physical and digital worlds in ways you've never imagined.",
    },
    {
      id: 5,
      image: "./images/e2.png",
      title: "Desktop Applications",
      detail:
        "Our desktop applications are tailored to offer high performance and reliability, addressing specific business needs in a comprehensive manner.",
    },
    {
      id: 6,
      image: "./images/factory-automation.png",
      title: "Factory Automation",
      detail: "Revolutionizing the manufacturing sector with our factory automation solutions, we aim to enhance efficiency, reduce costs, and increase productivity.",
    },
  ];


  return (
    <>
      <div className={styles["hero-section"]}>
        <h1 className={styles.heading}  data-aos="fade-up">Our Services </h1>
      </div>
      <div className={styles["process-container"]}>
        <h2 className={styles["software-heading"]}data-aos="fade-right">
          Empowering the Software Development Lifecycle from Ideation to Deployment
        </h2>
        <div className={styles["partner-container"]}>
          {partList.map((id) => (
            <Partner partners={id} />
          ))}
        </div>
      </div>
    </>
  );
};

export default OurServices;
