import React,{useEffect} from "react";
import  styles from "./About.module.css";
import { IoEyeOutline } from "react-icons/io5";
import { CiFlag1, CiHeart } from "react-icons/ci";
import AOS from "aos";
const About = () => {

  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);
  return (
    <div className="about">
      <div className={styles["main-img-container"]}>
        <div className={styles["main-img"]}></div>
        <div className={styles.heading} data-aos="fade-up">
          <h1>About Us</h1>
        </div>
      </div>
      <div className={styles["about-container"]}>
        <p>
          With over three years of experience in the tech industry, I've honed my skills and understanding, contributing significantly to projects that matter.
        </p>
        <br />
        <p>
          My journey includes fruitful collaborations with industry giants like Microsoft and Infosys, where I learned the intricacies of technological advancements and client satisfaction.
        </p>
        <br />
        <p>
          Driven by a passion for technology and a vision for the future, I founded 'Let's Create New'. Our company is proudly registered under the Udyam Aadhaar of the Indian government, marking our commitment to quality and innovation in the MSME sector.
        </p>
        <br />
        <p>
          I assure my customers of groundbreaking and game-changing ideas, born from a blend of my creativity and industry insights.
        </p>
      </div>
      <div className={styles["vision-container"]}>
        <div className={styles.vision}>
          <CiFlag1 className={styles.icon} />
          <h1>Our Mission</h1>
          <div className="content">
            <p>
            To help our clients develop their software products faster and better through 
            top-notch services and expertise. We also strive to create a great workplace that 
            attracts talented individuals and fosters their growth in the software industry..{" "}
            </p>
          </div>
        </div>
        <div className={styles.vision}>
          <IoEyeOutline className={styles.icon} />
          <h1>Our Vision</h1>
          <div className="content">
            <p>
            Our vision is to empower innovation through advanced software solutions, 
            catalyzing progress in technology and driving transformation across industries.
            </p>
          </div>
        </div>
        <div className={`${styles.vision} ${styles.last}`}>
          <CiHeart className={styles.icon} />
          <h1>Our Values</h1>
          <div className="content">
              <div>Focus on customer success</div>
              <div>Deliver the best</div>
              <div>Respect every individual</div>
              <div>Keep an open communication</div>
              <div>Create a collaborative environment</div>
              <div>Have passion for learning and growing</div>
              <div>Inspire others and work as a team</div>
          </div>
        </div>
      </div>
      <div className={styles["leadership-section"]}>
        <h2 className={styles["leader-heading"]}data-aos="fade-right">Our Members</h2>
        <div className={styles["leader-container"]}>
          <div className={`${styles["leader-img"]} ${styles.l1}`}></div>
          <div className={styles.leader}>
            <h2>Meet Bhuva</h2>
            <h3>Developer, Founder, and Innovator</h3>
            <br />
            <p>
              Hello, I'm Meet Bhuva, the driving force behind 'Let's Create New,' a venture that's more than just a company—it's a promise of innovation and creativity.
            </p>
            <p>
              With over three years of experience in the tech industry, I've honed my skills and understanding, contributing significantly to projects that matter.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
