import React, { useState, useRef } from "react";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import "./Header.css";
import "bootstrap/dist/css/bootstrap.min.css";
import companyLogo from '../assets/companyLogo.png'
import { NavLink } from "react-router-dom";

const CustomNavDropdown = ({ title, children, drop, dropend }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const timerRef = useRef(null);

  const handleMouseEnter = () => {
    clearTimeout(timerRef.current);
    setShowDropdown(true);
  };

  const handleMouseLeave = () => {
    timerRef.current = setTimeout(() => {
      setShowDropdown(false);
    }, 200);
  };

  return (
    <NavDropdown
      title={title}
      show={showDropdown}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      drop={drop}
      dropend={dropend}
    >
      {children}
    </NavDropdown>
  );
};

const Header = () => {
  
  return (
  
    <Navbar expand="lg" className="px-5 " style={{ padding: 0 }}>
      <Navbar.Brand as={NavLink} to="/" exact>
        {/* <img
          src="https://www.verifasttech.com/wp-content/uploads/2018/09/logo.png"
          srcSet="https://www.verifasttech.com/wp-content/uploads/2018/09/logo.png 1x, https://www.verifasttech.com/wp-content/uploads/2018/09/VeriFast-Retina-Logo.png 2x"
          width="227"
          height="35"
          alt="VeriFast Technologies Logo"
          data-retina_logo_url="https://www.verifasttech.com/wp-content/uploads/2018/09/VeriFast-Retina-Logo.png"
          className="fusion-standard-logo"
        /> */}
        <img
          src={companyLogo}
          width="100"
          alt="Let's Create New Company Logo"
          className="fusion-standard-logo"
        />

      </Navbar.Brand>
      <Navbar.Toggle aria-controls="navbarNavDropdown" />
      <Navbar.Collapse id="navbarNavDropdown">
        <Nav className="ms-auto">
          <Nav.Link as={NavLink} to="/" exact activeClassName="active">
            Home
          </Nav.Link>
          <Nav.Link as={NavLink} to="/Services" activeClassName="active">
            Our Services
          </Nav.Link>
          <Nav.Link as={NavLink} to="/Testimonials" activeClassName="active">
            Testimonials
          </Nav.Link>
          <Nav.Link as={NavLink} to="/About" activeClassName="active">
            About Us
          </Nav.Link>
          <Nav.Link as={NavLink} to="/ContactUs" activeClassName="active">
            Contact Us
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Header;
