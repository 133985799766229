import React from "react";
import "./Footer.css";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot, faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";

import {
  faSquareFacebook,
  faLinkedin,
  faSquareXTwitter,
  faYoutube,
  faInstagram
} from "@fortawesome/free-brands-svg-icons";
const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth" // Optional: smooth scrolling animation
    });
  };

  return (
    <div className="footer">
      <div className="sb_footer section_padding">
        <div className="sb_footer-links">
          <div className="sb_footer-links_div">
            <h4>Company</h4>
            <NavLink to="./About" onClick={scrollToTop}>
              <p>About Us</p>
            </NavLink>
            <NavLink to="./Testimonials" onClick={scrollToTop}>
              <p>Testimonials</p>
            </NavLink>
            <NavLink to="./ContactUs" onClick={scrollToTop}>
              <p>Contact Us</p>
            </NavLink>
          </div>
          <div className="sb_footer-links_div">
            <h4>Services</h4>
            <NavLink to="./Services" onClick={scrollToTop}>
              <p>Our Services</p>
            </NavLink>
          </div>
          <div className="sb_footer-links_div">
            <h4>Expertise</h4>
            <NavLink to="./Services" onClick={scrollToTop}>
              <p>Web Development</p>
            </NavLink>
            <NavLink to="./Services" onClick={scrollToTop}>
              <p>Web Applications</p>
            </NavLink>
            <NavLink to="./Services" onClick={scrollToTop}>
              <p>Mobile Application Development</p>
            </NavLink>
            <NavLink to="./Services" onClick={scrollToTop}>
              <p>IoT Devices and Software</p>
            </NavLink>
            <NavLink to="./Services" onClick={scrollToTop}>
              <p>Desktop Applications</p>
            </NavLink>
            <NavLink to="./Services" onClick={scrollToTop}>
              <p>Factory Automation</p>
            </NavLink>
          </div>
          
          <div className="sb_footer-links_div_add">
            <h4>Get In Touch</h4>
            <NavLink
              to="https://www.google.com/maps/place/Let's+create+new/@22.3239302,70.7941664,17z/data=!3m1!4b1!4m6!3m5!1s0x3959c902dde43c6f:0x36b33f9bf0106ea4!8m2!3d22.3239302!4d70.7941664!16s%2Fg%2F11ldm12xgz?entry=ttu"
              target="_blank"
            >
              {" "}
              <p>
                <FontAwesomeIcon icon={faLocationDot} className="op" />{" "}
                Rajkot, Gujarat
              </p>
            </NavLink>
            <NavLink to="tel:+1234567890" target="_blank">
              <p>
                <FontAwesomeIcon icon={faPhone} className="op" /> (+91) 9316129161
              </p>{" "}
            </NavLink>

            <NavLink to="mailto:inquiry@letscreatenew.in" target="_blank">
              <p>
                <FontAwesomeIcon icon={faEnvelope} className="op" />{" "}
                inquiry@letscreatenew.in
              </p>{" "}
            </NavLink>

            <div className="socialmedia">
              <NavLink to="https://www.linkedin.com/in/meet-bhuva-509498294?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app" target="_blank">
                {" "}
                <p>
                  <FontAwesomeIcon
                    icon={faLinkedin}
                    className="socialmedia_img"
                  />
                </p>{" "}
              </NavLink>

              <NavLink
                to="https://www.facebook.com/profile.php?id=100085612316626&mibextid=ZbWKwL" target="_blank">
                {" "}
                <p>
                  <FontAwesomeIcon
                    icon={faSquareFacebook}
                    className="socialmedia_img"
                  />
                </p>{" "}
              </NavLink>

              <NavLink
                to="https://www.instagram.com/letscreatenew15?utm_source=qr&igsh=aDVuMjR1ZDZvdDdp" target="_blank">
                {" "}
                <p>
                  <FontAwesomeIcon
                    icon={faInstagram}
                    className="socialmedia_img"
                  />
                </p>{" "}
              </NavLink>

              <NavLink
                to="https://youtube.com/@Letscreatenew?si=Cz1g4IFu7ymJkkJA" target="_blank">
                {" "}
                <p>
                  <FontAwesomeIcon
                    icon={faYoutube}
                    className="socialmedia_img"
                  />
                </p>{" "}
              </NavLink>


              <NavLink
                to="https://x.com/letscreatenew15?s=09" target="_blank">
                {" "}
                <p>
                  <FontAwesomeIcon
                    icon={faSquareXTwitter}
                    className="socialmedia_img"
                  />
                </p>{" "}
              </NavLink>

              
            </div>
          </div>
        </div>

        <hr></hr>
        <div className="sb_footer-below">
          <div className="sb_footer-copyright">
            <p>@{new Date().getFullYear()} Let's Create New. All right reserved.</p>
          </div>
          <div className="sb_footer-below-links">
            <a href="#">
              <div>
                <p>Terms & Conditions</p>
              </div>
            </a>
            <a href="#">
              <div>
                <p>Privacy</p>
              </div>
            </a>
            <a href="#">
              <div>
                <p>Security</p>
              </div>
            </a>
            <a href="#">
              <div>
                <p>Cookie Declaration</p>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
