import "./app.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Header from "./components/Header";
import Partners from "./pages/Partners";
import ContactUs from "./pages/ContactUs";
import Footer from "./components/Footer";
import OurServices from "./pages/OurServices";
const App = () => {
  return (
    <>
      <Router>
        <Header></Header>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/About" element={<About />} />
          <Route path="/Services" element={<OurServices/>} />
          <Route path="/Testimonials" element={<Partners />} />
          <Route path="/ContactUs" element={<ContactUs />} />
        </Routes>
        <Footer></Footer>
      </Router>
    </>
  );
};

export default App;
