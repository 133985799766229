import React,{useEffect} from "react";
import styles from "./Home.module.css";
import { NavLink } from "react-router-dom";
import AOS from "aos";

const Home = () => {

  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);
  
  return (
    <>
      <div className={styles["hero_section"]}>
        <div className={styles.main} >
          <h1 className={styles.main_header}data-aos="fade-down">Let's Create New</h1>
          <h1 className={styles["secondary_header"]}>
            Promise of Innovation and Creativity.
          </h1>
          <br/>
          <NavLink to="/Services">
            <p className={styles.button}>
              Explore Our Services  
            </p>
          </NavLink>
        </div>
      </div>
    </>
  );
};
export default Home;
