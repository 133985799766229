import React,{useEffect} from "react";
import styles from "./Partners.module.css";
import {NavLink} from "react-router-dom";
import AOS from "aos";
const Partners = () => {
  const Partner = (props) => {
    const { partners } = props;
    return (
      <div className={styles["partner-card"]}>
        <div className={styles.logo}>
          <img src={partners.image} alt="company logo"></img>
        </div>
        <div className={styles.detail}>
          <h1>{partners.title}</h1><br /><br />
          <p>{partners.detail}</p>
        </div>
      </div>
    );
  };
  const partList = [
    {
      id: 1,
      image: "./images/pixel-craft.jpg",
      title:"GreenTech Innovations",
      detail: "Meet's expertise in IoT devices transformed our environmental monitoring systems. His innovative approach not only made our devices smarter but also more energy-efficient, aligning perfectly with our sustainability goals.",
    },
    {
      id: 2,
      image: "./images/pixel-craft.jpg",
      title:"FastTrack Learning' - Mobile Application Development",
      detail:"The educational app created by Meet has been a game-changer for us. Our users love the interactive features, and the app’s performance is flawless. It's exactly what we needed to take our e-learning platform to the next level.",
    },
    {
      id: 3,
      image: "./images/pixel-craft.jpg",
      title:"HomeTech Solutions",
      detail:"Meet developed a custom desktop application for our home automation products. His ability to understand and implement our requirements was impressive. The application he delivered is both user-friendly and highly functional.",
    },
    {
      id: 4,
      image: "./images/pixel-craft.jpg",
      title:"Futura Auto - Factory Automation",
      detail:"Meet's contribution to automating our assembly line was pivotal. His solution has significantly increased our production efficiency and reduced downtime. His understanding of factory automation is top-notch",
    },
    {
      id: 5,
      image: "./images/pixel-craft.jpg",
      title:"PixelCraft Studios",
      detail:"As a digital design studio, we needed a sophisticated web platform. Meet delivered beyond our expectations, creating a website that beautifully showcases our portfolio and engages clients with its interactive design.",
    },
  ];
 
  useEffect(() => {
    AOS.init({ duration: 2000 });
    AOS.refresh();
  }, []);

  return (
    <>
    <div className={styles["hero-section"]}>
          <h1 className={styles.heading} data-aos="fade-up">What Our Clients Says</h1>
    </div>

      <div className={styles["partner-section"]}>
        <div className={styles["partner-container"]}>
          {partList.map((id) => (
            <Partner partners={id} />
          ))}
        </div>
      </div>

    </>
  );
};

export default Partners;
